import React from 'react'
import './footercss.css'

const Footer = () =>{
    return(
        <div>
            <div id='footer'>
            <span id='copyright'>&#169; 2023 - Warwick Creative Studios</span> 
               
            </div>
            
        </div>
    )
}

export default Footer